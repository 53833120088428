@import "./variables";
// @import "node_modules/@ckeditor/ckeditor5-table/theme/tablecolumnresize.css";
// @import "node_modules/@ctrl/ngx-emoji-mart/picker";

:root {
    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-color-selector-caption-background: hsl(0, 0%, 97%);
    --ck-color-selector-caption-text: hsl(0, 0%, 20%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-todo-list-checkmark-size: 16px;
}

.dialog-recruit {
    padding: 0 15px;
}

.des-html {
    h3 {
        padding-bottom: 8px;
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
    }
}

@media not all and (min-width: 1024px) {}

@media not all and (min-width: 768px) {
    .des-html {
        h3 {
            font-size: 16px;
            line-height: 36px;
        }
    }
}