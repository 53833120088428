@import "assets/scss/common.scss";
@import '@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100..900;1,100..900&display=swap");

body {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #273A42;
}

a {
    transition: all 0.3s ease-out;
}

a:hover,
.is-active {
   
    color: var(--color-active);
}

#header {
    .header-down {
        transition: all 0.4s ease-out;
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        -o-transform: translateY(0);
        -moz-transform: translateY(0);
        transform: translateY(0);

    }
}

#header {
    .header-up {
        transition: all 0.4s ease-out;
        -ms-transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

