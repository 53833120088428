$color-logo: #fad05d;
$color-white: #ffff;
$color-blue-base: rgb(69, 179, 248);
$color-background-table: rgb(248, 249, 251);
$color-header-text: #717d84;
$color-layout: #f8f8f8;
$color-breadcrum: #5f63f2;
$color-breadcrum-after: #8c94ff;
$color-side-bar: #fad87a;
$color-text: #000000;
$color-icon: #8f9bb3;
$color-active: #F79F1F;
$color-border: rgb(229, 229, 229);
$border-active: #fad05d;

:root {
    --color-active: #00ADFF;
    --color-title: #00ADFF;
    --color-default: #00ADFF;
    --color-text: rgba(20, 23, 28, 1);
    --divider: 229, 229, 229;
    --accent-brand-rgb: 59, 73, 223;
    --header-height: 56px;
    --layout-padding: var(--su-4);
    --su-05: 0.125rem;
    --su-1: 0.25rem;
    --su-2: 0.5rem;
    --su-3: 0.75rem;
    --su-4: 1rem;
    --su-5: 1.25rem;
    --su-6: 1.5rem;
    --su-7: 2rem;
    --su-8: 3rem;
    --su-9: 4rem;
    --su-10: 8rem;
}
